/* Функции */
import {
  defineComponent, PropType, VNode, HTMLAttributes,
} from 'vue'

/* Компоненты */
import VIcon from '@/components/VIcon/VIcon'
import VTextButton from '@/components/VText/VTextButton/VTextButton'

/* Стили */
import '@/components/VButton/VButton.scss'
import { TypeButtons } from './Types'

export default defineComponent({
  name: 'VButton',

  props: {
    /** Тег */
    tag: {
      type: String as PropType<string>,
      default: 'button',
    },

    /** Имя файла */
    imageName: {
      type: String as PropType<string>,
      default: '',
    },

    /** Вставлять ли svg в HTML */
    isIncludeSvgInHtml: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    /** Признак второй кнопки */
    [TypeButtons.Secondary]: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак третьей кнопки */
    [TypeButtons.Tertiary]: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак четвертой кнопки */
    [TypeButtons.Quaternary]: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак пятой кнопки */
    [TypeButtons.Fivefold]: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак шестой кнопки */
    [TypeButtons.Sixfold]: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    /** Признак блокировки */
    [TypeButtons.Disabled]: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    onClick: {
      type: Function as PropType<HTMLAttributes['onClick']>,
    },

    [TypeButtons.FullWidth]: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    positionIcon: {
      type: String as PropType<'left' | 'right'>,
      default: 'right',
    },

    /** Признак малой кнопки */
    [TypeButtons.Small]: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  render(): VNode {
    const nameComponent = this.tag
    const isShowIcon = Boolean(this.imageName)
    const classes = {
      button: true,
      secondary: this.isSecondary,
      tertiary: this.isTertiary,
      quaternary: this.isQuaternary,
      fivefold: this.isFivefold,
      sixfold: this.isSixfold,
      disabled: this.isDisabled,
      primary: !this.isSecondary && !this.isTertiary && !this.isQuaternary && !this.isFivefold && !this.isSixfold,
      'full-width': this.isFullWidth,
      'is-small': this.isSmall,
    }
    const icon = isShowIcon
      ? <VIcon imageName={this.imageName
    } isIncludeSvgInHtml={this.isIncludeSvgInHtml} />
      : null

    return (
      <nameComponent onClick={this.onClick} class={classes} disabled={this.isDisabled} >
        {this.positionIcon === 'left' ? icon : null}

        <VTextButton>{this.$slots.default?.()}</VTextButton>

        {this.positionIcon === 'right' ? icon : null}
      </nameComponent >
    )
  },
})
