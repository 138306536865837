/* Функции */
import { defineComponent, VNode } from 'vue'

/* Компоненты */
import BasePageAction from '@/components/BasePageAction/BasePageAction'
import VListIcons from '@/components/VListIcons/VListIcons'
import VButtonFixes from '@/components/VButton/VButtonIcon/VButtonFixes/VButtonFixes'
import VTextButton from '@/components/VText/VTextButton/VTextButton'
import BasePaymentOptionChoice from '@/components/BasePaymentOptionChoice/BasePaymentOptionChoice'
import VContentItem from '@/components/VContent/VContentItem/VContentItem'
import VIcon from '@/components/VIcon/VIcon'
import VRow from '@/components/VRow/VRow'
/* Стили */
import '@/views/Plans/PlansDetail/PlansDetail.scss'

/* Классы */
import { RequestAPI } from '@/classes/Request'

/* Типы */
import {
  AddPaymentResponse, PaymentMethodsResponse, Plan, PlanResponse, ResponseGoals, Subscription, SubscriptionResponse, PaymentOptions,
} from '@/types/API'
import { RoutePaths } from '@/router/Types'
import { Data } from '@/views/Plans/PlansDetail/Types'

/* Хранилище */
import { LayoutStore } from '@/store/layout'

export default defineComponent({
  name: 'PlansDetail',

  data(): Data {
    return {
      isShowPage: false,
      plans: [],
      currentPayment: null,
      subscriptions: [],
      planId: null,
    }
  },

  computed: {
    /** Выбранный план */
    currentPlan(): Plan | null {
      return this.plans.find((plan) => plan.id === Number(this.$route.params.id)) ?? null
    },
    /** Описание */
    description(): string {
      return this.currentPlan?.description ?? ''
    },
    /** Заголовок */
    title(): string {
      return this.currentPlan?.title ?? ''
    },
    /** Блокировка кнопки */
    isDisabledButton(): boolean {
      return this.currentPayment === null
    },
    /** Выбранная опция */
    currentPaymentOption(): PaymentOptions | undefined {
      return this.currentPlan?.payment_options.find((paymentOption) => paymentOption.id === this.currentPayment)
    },
  },

  methods: {
    /** Смена выбранного тарифа */
    onChecked(value: string | number | null) {
      this.currentPayment = Number(value)
    },

    /** Выбор плана */
    async choicePlan() {
      const response = await RequestAPI.errorHandler(RequestAPI.post, '/subscriptions', {
        subscription_plan_option_id: this.currentPayment,
        trial: true,
      })
      const isFree = this.currentPaymentOption?.price === 0

      if (isFree) {
        if (response) {
          await this.setGoal()
        } else {
          await this.setSubscriptions()
        }

        this.$router.push({ path: RoutePaths.main })
        return
      }

      if (response) {
        await this.setGoal()

        if (!this.subscriptions.length) {
          await this.addNewPaymentMethod()
        } else {
          this.$router.push({ path: RoutePaths.subscriptions })
        }
      } else {
        await this.setSubscriptions()
        const findSubscription = this.subscriptions.find((subscription) => subscription.plan.id === this.planId)

        if (findSubscription) {
          const savedMethods = await this.getSavedMethods()

          if (!savedMethods.length) {
            await this.addNewPaymentMethod()
          }
        }
      }
    },

    /** Добавление метода платежного */
    async addNewPaymentMethod() {
      const response = await RequestAPI.errorHandler<AddPaymentResponse>(RequestAPI.post, '/payments/saved_methods')

      if (!response) {
        return
      }

      const { data } = response

      if (data.content.payment_link) {
        window.location.href = data.content.payment_link
      }
    },

    /** Запрашиваем и устанавливаем подписки  */
    async setSubscriptions() {
      const response = await RequestAPI.errorHandler<SubscriptionResponse>(RequestAPI.get, '/subscriptions')

      if (response) {
        const { data } = response
        this.subscriptions = data.content.subscriptions.reduce<Subscription[]>((subscriptions, subscription) => {
          if (subscription.state === 'current') {
            subscriptions.push(subscription)
          }

          return subscriptions
        }, [])
      }
    },

    /** Установка цели */
    async setGoal() {
      if (this.$route.query.goal_id) {
        await RequestAPI.errorHandler<ResponseGoals>(RequestAPI.post, `/goals/${this.$route.query.goal_id}`)
      }
    },

    /** Установка сохраненных платежных данных  */
    async getSavedMethods() {
      const response = await RequestAPI.errorHandler<PaymentMethodsResponse>(RequestAPI.get, '/payments/saved_methods')

      if (response) {
        const { data } = response
        return data.content.payment_methods
      }

      return []
    },
  },

  async created() {
    LayoutStore().setLoading(true)

    const response = await RequestAPI.errorHandler<PlanResponse>(RequestAPI.get, '/plans')
    await this.setSubscriptions()

    if (response) {
      const { data } = response
      this.plans = data.content.plans
    }

    if (!Array.isArray(this.$route.params.id)) {
      this.planId = Number(this.$route.params.id)
    }

    LayoutStore().setLoading(false)
    this.isShowPage = true
  },

  render(): VNode {
    const choiceList = this.currentPlan?.payment_options.map((option) => (
      <BasePaymentOptionChoice
        price={option.price}
        description={option.description ?? ''}
        modelValue={this.currentPayment}
        onUpdate:checked={this.onChecked}
        value={option.id}
        name={'choice_plan'}
      >
        {option.title}
      </BasePaymentOptionChoice>
    ))

    return (
      <BasePageAction
        color='#8B5CF6'
        class="plan-detail"
        isShow={this.isShowPage}
        title={this.title}
        onClick={() => this.$router.back()}
      >
        <VListIcons html={this.description} />

        <VContentItem>

          {choiceList}
        </VContentItem>

        <VButtonFixes
          onClick={this.choicePlan}
          isDisabled={this.isDisabledButton}
          isFullWidth={true}
        >
          <VRow>
            <VIcon
              imageName={'pro'}
            />

            <VTextButton
              colorText={'#fff'}
              class="plan-detail__text"
            >
              {this.$t('pages.Plans.button', { payment: this.currentPlan?.label })}
            </VTextButton>
          </VRow>
        </VButtonFixes>
      </BasePageAction>
    )
  },
})
